<template>
  <v-container>
    <v-row>
      <v-col>
        <TableCrudComponent endpoint="/api/projects" table-name="Projects"></TableCrudComponent>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import TableCrudComponent from "@/components/tables/TableCrudComponent";
export default {
  name: "ProjectOverviewPage",
  components: {TableCrudComponent}
}
</script>

<style scoped>

</style>
